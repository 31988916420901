<template>
  <div class='element materialShadow dashboard-box' :class="sizeClass">
    <h2 class='headline'>{{ x(headline) }}</h2>
    <table>
      <thead>
      <tr>
        <td class='Theme_C1_BG'>{{ x('name') }}</td>
        <td class='Theme_C1_BG'>{{ x('count') }}</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in rows" :key="index">
        <td>{{ attemptTranslation(row.vType) }}</td>
        <td>{{ row.vCount }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import translate from '../Mixins/Translate';

  export default {
    name: 'dashboard-box',
    props: ['headline', 'rows', 'sizeClass'],
    mixins: [translate],
    methods: {
      attemptTranslation(phrase) {
        let translated = this.x(phrase.toLowerCase());
        if (translated.startsWith('MISSING')) translated = phrase;
        return translated;
      },
    },
  };
</script>

<style scoped lang="scss">
  .dashboard-box {
    min-height: 265px;

    h2 {

      &.headline {
        color: var(--ThemeDashboardBoxHeadline) !important;
      }
    }
  }
</style>
