<template>
  <div id="dashboard" ref="dashboard">
    <dashboard-graph :items="overallWeekday"/>
    <dashboard-box sizeClass="size_100" headline="periodTotal" :rows="overall"/>
    <dashboard-box sizeClass="size_33" headline="districtsDashMostVisits"
                   :rows="districtVisits"/>
    <dashboard-box v-if="config.features[featureNames.likes]" sizeClass="size_33"
                   headline="districtsDashMostLikes" :rows="districtLikes"/>
    <dashboard-box v-if="config.features[featureNames.comments]" sizeClass="size_33"
                   headline="districtsDashMostComments" :rows="districtComments"/>
    <dashboard-box sizeClass="size_33" headline="usersDashMostVisits"
                   :rows="userVisits"/>
    <dashboard-box v-if="config.features[featureNames.likes]" sizeClass="size_33"
                   headline="usersDashMostLikes" :rows="userLikes"/>
    <dashboard-box v-if="config.features[featureNames.comments]" sizeClass="size_33"
                   headline="usersDashMostComments" :rows="userComments"/>
    <dashboard-box sizeClass="size_33" headline="productsDashMostVisits"
                   :rows="brandVisits"/>
    <dashboard-box v-if="config.features[featureNames.likes]" sizeClass="size_33"
                   headline="productsDashMostLikes" :rows="brandLikes"/>
    <dashboard-box v-if="config.features[featureNames.comments]" sizeClass="size_33"
                   headline="productsDashMostComments" :rows="brandComments"/>
    <div class="lsc-clear"></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { forOwn } from 'lodash';
  import moment from 'moment';
  import { httpGet } from '@/classes/httpHelper';
  import featureNames from '@/enums/feature-names';
  import DashboardBox from './DashboardBox';
  import DashboardGraph from './DashboardGraph';

  export default {
    name: 'dashboard',
    components: {
      'dashboard-box': DashboardBox,
      'dashboard-graph': DashboardGraph,
    },
    data() {
      return {
        overallWeekday: [],
        overall: [],
        districtVisits: [],
        districtLikes: [],
        districtComments: [],
        userVisits: [],
        userLikes: [],
        userComments: [],
        brandVisits: [],
        brandLikes: [],
        brandComments: [],
        featureNames,
      };
    },
    computed: mapState(['config', 'user', 'dashboardOptions']),
    watch: {
      async dashboardOptions() {
        await this.loadStats();
      },
    },
    async mounted() {
      await this.loadStats();
    },
    methods: {
      async loadStats() {
        const options = this.$store.state.dashboardOptions;
        if (options.selectedChannels.length === 0) return;

        const selectedUserRoles = [];
        forOwn(options.userRoles, (value) => {
          selectedUserRoles.push(value);
        });

        const endDate = moment().format('DD-MM-YYYY');
        const startDate = moment().subtract((options.days - 1), 'days').format('DD-MM-YYYY');
        const userRoles = selectedUserRoles.join(',');
        const channels = options.selectedChannels.join(',');
        const url = `dashboardstats?periodfrom=${startDate}&periodto=${
          endDate}&userroles=${userRoles}&channels=${channels}`;

        const response = await httpGet(url);
        this.parseData(response);
      },
      parseData(data) {
        this.overallWeekday = [];
        this.overall = [];
        this.districtVisits = [];
        this.districtLikes = [];
        this.districtComments = [];
        this.userVisits = [];
        this.userLikes = [];
        this.userComments = [];
        this.brandVisits = [];
        this.brandLikes = [];
        this.brandComments = [];

        for (const item of data) {
          switch (item.vGroup) {
            case 'OverallWeekDay':
              this.overallWeekday.push(item);
              break;
            case 'Overall':
              this.overall.push(item);
              break;
            case 'Region visits':
              this.districtVisits.push(item);
              break;
            case 'Region likes':
              this.districtLikes.push(item);
              break;
            case 'Region comments':
              this.districtComments.push(item);
              break;
            case 'User visits':
              this.userVisits.push(item);
              break;
            case 'User likes':
              this.userLikes.push(item);
              break;
            case 'User comments':
              this.userComments.push(item);
              break;
            case 'Brand visits':
              this.brandVisits.push(item);
              break;
            case 'Brand likes':
              this.brandLikes.push(item);
              break;
            case 'Brand comments':
              this.brandComments.push(item);
              break;
            default:
              console.error('Item was not sorted: ', item.vGroup);
              break;
          }
        }
      },
    },
  };
</script>
