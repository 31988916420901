<template>
  <div class='element materialShadow size_100 dashboard-graph'>
    <h2 class='headline'>
      {{ $store.state.config.translation['visitsPerDay'] }}
    </h2>
    <dl class='graph'>
      <dt v-for="(bar, index) in bars" :key="index" :style="barStyle(bar)" class='Theme_C1_BG'>
        <span v-if="bar!==0">{{ bar }}</span>
      </dt>
      <dd v-for="label in labels" :key="label" :style="'width:calc(' + barWidth + '% - 5px);'"
          class="Theme_C1_Headline Theme_C3_Border">
        {{ label }}
      </dd>
    </dl>
  </div>
</template>

<script>
  import moment from 'moment';
  import { sortBy } from 'lodash';

  export default {
    name: 'dashboard-graph',
    props: ['items'],
    data() {
      return {
        bars: [], // days or months
        labels: [],
        graphDivider: 0,
        barWidth: 1,
        showAsMonths: false,
        mappedItems: [],
      };
    },
    watch: {
      items() {
        this.parseItems();
      },
    },

    methods: {
      barStyle(bar) {
        const style = {
          width: `calc(${this.barWidth}% - 5px)`,
          height: `${bar * this.graphDivider}px`,
        };
        if (bar === 0) {
          style.padding = 0;
          style.minHeight = 0;
        }
        return style;
      },
      parseItems() {
        if (this.items.length === 0) return;
        // If showing much more than two months, bundle it as months.
        this.showAsMonths = (this.items.length > 65);
        if (this.showAsMonths) {
          this.mappedItems = this.mapDaysToMonths(this.items);
        } else {
          this.mappedItems = sortBy(this.items, [(item) => moment(item.vType, 'DD-MM-YYYY').format('YYYYMMDD')]);
        }
        let graphMaxValue = 0;

        for (const item of this.mappedItems) {
          graphMaxValue = Math.max(graphMaxValue, item.vCount);
        }
        if (graphMaxValue === 0) {
          this.graphDivider = 10;
        } else {
          this.graphDivider = 193 / graphMaxValue;
        }

        if (this.showAsMonths) {
          this.labels = this.mappedItems.map((item) => moment(item.vType, 'YYYY-MM').format('MM/YY'));
        } else {
          this.labels = this.mappedItems.map((item) => moment(item.vType, 'DD-MM-YYYY').format('DD/MM'));
        }
        this.bars = this.mappedItems.map((item) => item.vCount);
        this.barWidth = 100 / this.mappedItems.length;
      },

      mapDaysToMonths(list) {
        const aggregated = {};
        for (const item of list) {
          const month = moment(item.vType, 'DD-MM-YYYY').format('YYYY-MM');
          if ('month' in aggregated) {
            aggregated[month].vCount += item.vCount;
          } else {
            aggregated[month] = { vType: month, vCount: item.vCount };
          }
        }

        return sortBy(aggregated, ['vType']);
      },
    },
  };
</script>

<style scoped lang="scss">
.dashboard-graph {
  min-height: 265px;
  position: relative;

  h2.headline {
    color: var(--ThemeDashboardBoxHeadline) !important;
  }

  .graph {
    position: absolute;
    bottom: 0;
    left: 3px;
    display: block;
    width: 100%;

    dt, dd {
      display: inline-block;
      margin-right: 5px;
      overflow: hidden;
      text-align: center;
    }

    dt {
      text-align: center;
      color: #ffffff;
      padding: 8px 0;
      min-height: 35px;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        color: #444444;
        background: #ffffff;
        font-size: 10px;
        padding-top: 3px;
        font-weight: 700;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
      }
    }

    dd {
      height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-top: 1px solid #000000;
      margin-top: 1px;
      padding: 3px 0 0 0;
      font-size: 10px;
    }
  }
}
</style>
